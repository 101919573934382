import React from "react"
import { Styled } from "theme-ui"
import AniLink from "gatsby-plugin-transition-link/AniLink";

/**
 * Change the content to add your own bio
 */

export default () => (
  <footer className="container">

  <div className="md:px-2" >
    &copy; copyright 2020 <AniLink cover to='/' bg="#68D391">Geekynut.</AniLink>
  
  
    <br />
    Geeky stuff and more!
  </div>
  </footer>
)
